@font-face {
  font-family: 'Aleo';
  src: url("../fonts/aleo/aleo-light-webfont.eot");
  src: url("../fonts/aleo/aleo-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-light-webfont.woff2") format("woff2"), url("../fonts/aleo/aleo-light-webfont.woff") format("woff"), url("../fonts/aleo/aleo-light-webfont-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aleo';
  src: url("../fonts/aleo/aleo-lightitalic-webfont.eot");
  src: url("../fonts/aleo/aleo-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-lightitalic-webfont.woff2") format("woff2"), url("../fonts/aleo/aleo-lightitalic-webfont.woff") format("woff"), url("../fonts/aleo/aleo-lightitalic-webfont-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aleo';
  src: url("../fonts/aleo/aleo-regular-webfont.eot");
  src: url("../fonts/aleo/aleo-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-regular-webfont.woff2") format("woff2"), url("../fonts/aleo/aleo-regular-webfont.woff") format("woff"), url("../fonts/aleo/aleo-regular-webfont-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aleo';
  src: url("../fonts/aleo/aleo-italic-webfont.eot");
  src: url("../fonts/aleo/aleo-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-italic-webfont.woff2") format("woff2"), url("../fonts/aleo/aleo-italic-webfont.woff") format("woff"), url("../fonts/aleo/aleo-italic-webfont-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Aleo';
  src: url("../fonts/aleo/aleo-bold-webfont.eot");
  src: url("../fonts/aleo/aleo-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-bold-webfont.woff2") format("woff2"), url("../fonts/aleo/aleo-bold-webfont.woff") format("woff"), url("../fonts/aleo/aleo-bold-webfont-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aleo';
  src: url("../fonts/aleo/aleo-bolditalic-webfont.eot");
  src: url("../fonts/aleo/aleo-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-bolditalic-webfont.woff2") format("woff2"), url("../fonts/aleo/aleo-bolditalic-webfont.woff") format("woff"), url("../fonts/aleo/aleo-bolditalic-webfont-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
