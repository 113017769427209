.cookie-spacer{
    display:none;
    position:relative;
    width:100%;
}

.cookie-banner {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #222;
    padding: 5px 10px;
    z-index: 9999;
    color: #fff;
}

.cookie-btn-container {
    white-space: nowrap;
  flex-grow: 0;
}

.cookie-banner-inner {
    display: flex;
    align-items: center;
  justify-content: space-between;
}

.cookie-banner-text{
  flex-grow: 0;
}

.cookie-banner p {
    margin: 0;
    padding: 0;
}

.cookie-banner a {
    color: #fff;
    text-decoration: underline;
}

.cookie-banner .cookie-btn {
    float: right;
    text-decoration: none;
    background: #fff;
    color: #000;
    border-radius: 3px;
    padding: 5px 10px;
    margin:0 5px;
    white-space: nowrap;
}

.cookie-banner .cookie-btn:hover {
    color: #000;
    background: #eee;
    text-decoration: none;
}


@media (max-width: 768px) {
    .cookie-banner-inner {
        display: block;
    }

    .cookie-btn-container{
        padding: 10px 0;
    }
}
