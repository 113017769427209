//custom variables go here

$font-family-sans-serif: 'Aleo', sans-serif !default;
$font-size-base: 17px;

$brand-bitta: #ee7352;
$brand-bubi: #36a2b1;

$brand-primary: $brand-bubi;

$container-large-desktop: 970px !default;
