@import 'fonts';

$dark-color: #333;
$bg-color: #f0f6f6;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark-color;
  background: $bg-color;
}

.special-font {
  font-family: 'Lato', sans -serif;
  font-weight: 900;
}

.header {
  background: darken($bg-color, 3%);
  border-bottom: 2px solid darken($bg-color, 7%);
}

.logo-link {
  margin: 40px 0 40px;
  display: inline-block;
  fill: $dark-color;

  img {
    width: 260px;
    height: 95px;
  }
}

.top-links {
  text-align: center;
  margin-top: 10px;

  a {
    color: $dark-color;
  }
}

.navigation {
  margin: 0;
  padding: 0 0 30px;
  text-align: center;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
    display: inline-block;

    @media (max-width: $screen-sm-max) {
      display: block;
    }

    &.active a {
      color: $brand-bitta;
    }
  }

  a {
    @extend .special-font;
    display: inline-block;
    padding: 10px 30px;
    font-size: 1.1em;
    text-align: center;
    color: $dark-color;
    text-transform: uppercase;
    letter-spacing: 1px;


  }
}

.sub-title {
  margin: 40px 0 20px;
  @extend .special-font;
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 0 25px;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -11px;
    width: 20px;
    height: 24px;
    background: url('../img/hopfen.svg') no-repeat;
    background-size: auto 24px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.wysiwyg {
  & > *:last-child {
    margin-bottom: 0;
  }
}

.page-title {
  margin-bottom: 30px;

  h1 {
    @extend .special-font;
    margin: 0;
    text-align: center;
    display: inline-block;
    padding: 0 10px 10px;
  }

  p {
    font-weight: bold;
  }

  .crown {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: auto;
  }
}

.beer-box {
  position: relative;
  padding: 50px 0;
  margin-bottom: 20px;

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 10px -5px, transparent 12px, $bg-color 13px);
    background-position: top -10px;
    transform: rotate(180deg);
  }

  &:after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 10px -5px, transparent 12px, $bg-color 13px);
  }
}

.label-img {
  max-height: 400px;
}

.label-box {
  .label-box-header {
    background: #fff url('../img/crown.svg') center 5px no-repeat;
    background-size: auto 50px;
    border-radius: 95px 95px 0 0;
    border: 2px solid $dark-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    padding: 57px 5px 5px;

    h2 {
      margin: 0 0 3px;
      font-size: 1.7em;
    }

    h4 {
      margin: 0;
      font-size: 1em;
    }
  }

  .label-box-body {
    background: #fff ;
    border: 2px solid $dark-color;
    border-top: 0;
    padding: 10px;

    hr {
      margin: 10px -10px;
      border-top: 2px solid $dark-color;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  .bottle-info {
    text-align: right;
    font-size: 1.3em;

    .icon-e {
      margin-left: 5px;
      position: relative;
      top: -2px;
    }
  }
}

.footer {
  background: transparent;
  padding: 60px 0 50px;
  color: inherit;
  font-size: 0.9em;

  h5 {
    font-weight: bold;
  }

  a {
    color: $dark-color;
    text-decoration: underline;

    &:hover {
      color: #000;
    }
  }
}

.btn-custom {
  border: 0;
  font-weight: bold;
  @include box-shadow(3px 3px 10px rgba(0, 0, 0, 0.3));
  font-size: 1.2em;
  padding: 10px 40px;
}
